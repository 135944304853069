import React from 'react'
import './contact.css'
import {SiMinutemailer, SiMessenger, SiWhatsapp} from 'react-icons/si'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cngmfw9', 'template_134udyc', form.current, 'o6yR7BDfLgcJzP87_')
    e.target.reset();
  };
  return (
    <section id='contact'> 
      <h5>Get in touch</h5>
      <h2>Connect with me</h2>

      <div className="container contact__container">
        <div className="contact__options">
        {/* Email */}
        <article className="contact__option">
          <SiMinutemailer className='contact__option-con'/>
          <h4>Email</h4>
          <h5>pedrochakent@gmail.com</h5>
          <a href="mailto:pedrochakent@gmail.com" target='_blank'>Send an email</a>
        </article>

        {/* Messenger */}
        <article className="contact__option">
          <SiMessenger className='contact__option-con' />
          <h4>Messenger</h4>
          <h5>pedrochakent@gmail.com</h5>
          <a href="https://m.me/kpeds25" target='_blank'>Chat me on messenger</a>
        </article>

        {/* Whatsapp */}
        <article className="contact__option">
          <SiWhatsapp className='contact__option-con' />
          <h4>WhatsApp</h4>
          <h5>(647) 819-5712</h5>
          <a href="https://api.whatsapp.com/send?phone=+16478195712" target='_blank'>Message me on WhatsApp</a>
        </article>
        </div>
      {/* END OF CONTACT OPTIUONS */}

      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Enter your name" id="" required />
        <input type="email" name="email" placeholder='e.g. xyz@gmail.com' id="" />
        <textarea name="message" rows="7" placeholder= "Enter a message" required></textarea>
        <button type='submit' className='btn btn-primary'>Send</button>
      </form>
      </div>


      
    </section>
  )
}

export default Contact