import React from 'react'
import './services.css'
import {FaRegCheckSquare} from 'react-icons/fa'
import Project_Summary from '../../assets/credentials/capstone/F21_T10_Project_Summary.docx'
import ProjectVision from '../../assets/credentials/capstone/F21_T10_Project_Vision.docx'
import ProjectBusinessRequirements from '../../assets/credentials/capstone/F21_T10_High_Level_Requirments.doc'
import ProjectPlan from '../../assets/credentials/capstone/W22_T10_Project_Plan_v1.1.docx'
import Requirements from '../../assets/credentials/capstone/F21_T10_Requirements Analysis and Design.docx'
import Wireframes from '../../assets/credentials/capstone/F21_T10_Mockup.pdf'
import StatusReports  from '../../assets/credentials/capstone/W22_T10_ProjectReport2.docx'
import cert from '../../assets/credentials/t10-chatbot.pdf'

const docs = [
    {
      id: 1,
      title: "Project Summary",
      uri: Project_Summary
    },
    {
        id: 2,
        title: "Projecy Vision",
        uri: ProjectVision
    },
    {
        id: 3,
        title: "Project/Business Requirements",
        uri: ProjectBusinessRequirements
    },
    {
        id: 4,
        title: "Project Plan",
        uri: ProjectPlan
    },
    {
        id: 5,
        title: "Requirements Analysis and Design ",
        uri: Requirements
    },
    {
        id: 6,
        title: "Wireframes",
        uri: Wireframes
    },
    {
        id: 7,
        title: "Status Reports",
        uri: StatusReports
    },
  ]

const Services = () => {
  return (
    <section id="services">
        <h5>Capstone Project</h5>
        <h2>Chatbot Web-App for IT Clan Services</h2>

        <div className="container services__container">
        <article className="service">
                <div className="service__head">
                    <h3>
                        Brief Description
                    </h3>
                </div>
            <ul className="service__list">
                {/* 1 */}
                <li>
                    <p>The Chatbot Web App will serve as a first-line communicator to the customers. It will provide information within the IT Clan's data regarding the customers' queries. When the customer's question is far beyond the Chatbot's knowledge, it can hand in the customer to a real agent. The Chatbot also can display menu options and FAQs. It will be trainable and can converse naturally with customers.</p>
                </li>

            </ul>
            </article>

            <article className="service">
                <div className="service__head">
                    <h3>
                        Documentations
                    </h3>
                </div>
                
                {
          docs.map(({id, title, uri}) => {
            return (
                <ul key={id} className="service__list">
                    {/* 1 */}
                    <li>
                        <FaRegCheckSquare className='service__list-icon'/>
                        <p>
                        <a href={uri} target='_blank'>{title}</a></p>
                    </li>
                </ul>
            );
          })
        }
            </article>
{/* End UI/UX*/}



{/* END OF WEB DEVELOPMENT */}

            <article className="service">
                <div className="service__head">
                    <h3>
                        Course Certification
                    </h3>
                </div>
            <ul className="service__list">
                {/* 1 */}
                <li>
                    <FaRegCheckSquare className='service__list-icon'/>
                    <p>
                    <a href={cert} target='_blank'>Group Udemy Certification</a>
                    </p>
                </li>
            </ul>
            </article>
        </div>



    </section>


  )
}

export default Services