import React from 'react'
import Header from './components/header/Header'
import About from './components/about/About'
import Portfolio from './components/portfolio/Portfolio'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Testimonials from './components/testimonials/Testimonials'
import Nav from './components/nav/Nav'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'


import Roll from 'react-reveal/Roll';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';
import Zoom from 'react-reveal/Zoom';




export const App = () => {
  return (
    <>
      <Header />

      <Roll left>
      <About />
      </Roll>

      <Bounce>
        <Portfolio />
      </Bounce>

      <Fade left>
        <Experience />
      </Fade>

      <Fade right>
        <Services />
      </Fade>

      <LightSpeed>
        <Testimonials />
      </LightSpeed>

      <Zoom>
        <Contact />
      </Zoom>
      <Footer />

      <Nav />
    </>
  )
}

export default App;