import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/img/header-kent.png'
import HeaderSocials from './HeaderSocials'
import Typical from 'react-typical'
import RubberBand from 'react-reveal/RubberBand';


const Header = () => {
  return (
    <header>
      <div className="container header__container">
          <h3>Hello My name is</h3>
          <RubberBand>
            <h1>Kent Pedrocha</h1>
          </RubberBand>
          <h3 className="text-light-black">I am a
            <strong>
            <Typical
              steps={['Full-stack Developer.', 800, 'Student.', 800, 'Team Player.', 800, 'Believer.', 800]}
              loop={Infinity}
              wrapper="p"
            />
            </strong>
          </h3>
          <CTA />
          <HeaderSocials />

          <div className='me'>
            <img src={ME} alt="" />
          </div>

          <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header