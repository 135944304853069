import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/img/AVTR1.jpg'
import AVTR2 from '../../assets/img/AVTR2.jpg'
import AVTR3 from '../../assets/img/AVTR3.jpg'
import AVTR4 from '../../assets/img/AVTR4.jpg'
import AVTR5 from '../../assets/img/AVTR5.jpg'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';// import Swiper core and required modules
import { Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: "Macario Bueno",
    review: "Kent is a hardworker person. I highly recommend him!"
  },
  {
    avatar: AVTR2,
    name: "Lance Lutherford",
    review: "He's helpful. You can count on him whenever you need!"
  },
  {
    avatar: AVTR3,
    name: "Danille Clement",
    review: "He can pursuade me anything as long as he provides research."
  },
  {
    avatar: AVTR4,
    name: "Stephen Saquita",
    review: "Kent hmmm, he's a smart guy. I like him because he's informative."
  },
  {
    avatar: AVTR5,
    name: "Kent Pedrocha",
    review: "Thank you for all the love and support. I will keep doing good work!"
  },
]

const Testimonials = () => {
  return (
    <section id='testimonials'> 
      <h5>What I hear from people</h5>
      <h2>Recommendations</h2>
    

      <Swiper className="container testimonials__container"
            // install Swiper modules
            modules={[Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
      >


        {
          data.map(({index, avatar, name, review}) =>{
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="AVAAA" />
              </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{review}</small>
            </SwiperSlide>
            );
          })
        }

        {/* ENBD */}

      </Swiper>
    </section>
  )
}

export default Testimonials