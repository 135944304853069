import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/img/portfolio1.png'
import IMG2 from '../../assets/img/portfolio2.png'
import IMG3 from '../../assets/img/portfolio3.png'

import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    id: 1,
    image: IMG1,    
    title: "Clinic Management System",
    github: "",
    desc: "In this project, we apply object-oriented programming in creating the system. We maximize the use of inheritance, polymorphism, and encapsulation during the semester. ",
    demo: IMG1
  },
  {
    id: 2,
    image: IMG2,    
    title: "Product's static web pages",
    github: "https://github.com/KentCTSchool/FullstackAssignment",
    desc: "In this assignment, we used HTML and CSS to apply the knowledge we acquired during the semester. It mainly prioritizes the use of proper formatting and design throughout the whole semester.  ",
    demo: IMG2
  },
  {
    id: 3,
    image: IMG3,    
    title: "SportsPro Website",
    github: "https://github.com/yyp1023/comp3095_assignment",
    desc: "with ASP.NET Core MVC, we created a website that holds a database for products, technicians, customers, incidents, and registrations. We made a secured website that articulates the data with MVC Architecture.",
    demo: IMG3
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'> 
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
    
      <div className="container portfolio__container">
        {
          data.map(({id, image, title, desc, demo}) => {
            return (
              <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <br></br>
                <small>
                  {desc}
                </small>  
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={demo} className='btn' target='_blank'>Github</a>
                <a href={demo} className='btn btn-primary' target='_blank'>View Image</a>
              </div>
            </article>
            );
          })
        }
      </div>
    
    </section>
  )
}

export default Portfolio