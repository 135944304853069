import React from 'react'
import Resume from '../../assets/credentials/KentPedrochaRESUME.pdf'

const CTA = () => {
  return (
    <div className='cta'>
        <a href={Resume} download className='btn' target='_blank'>View Resume</a>
        <a href='#contact' className='btn btn-primary'>Let's talk</a>
    </div>
  )
}

export default CTA