import React from 'react'
import './experience.css'
import {GiCheckMark} from 'react-icons/gi'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Skills I have gained</h5>
      <h2>Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Front-end Development</h3>
          <div className="experience__content">
            {/* 1 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
                <small>
                  Experienced
                </small>
              </div>
            </article>

            {/* 2 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small>
                  Advanced Beginner
                </small>
              </div>
            </article>

            {/* 3 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small>
                  Experienced
                </small>
              </div>
            </article>

            {/* 4 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>React</h4>
                <small>
                  Advanced Beginner
                </small>
              </div>
            </article>

            {/* 5 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small>
                  Advanced Beginner
                </small>
              </div>
            </article>

            {/* 5 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>Angular</h4>
                <small>
                  Novice
                </small>
              </div>
            </article>
          </div>
        </div>
{/* BACKEND */}
        <div className="experience__backend">
          <h3>Back-end Development</h3>
          <div className="experience__content">
            {/* 1 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>Java</h4>
                <small>
                  Experienced
                </small>                
              </div>
            </article>

            {/* 2 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>PHP </h4>
                <small>
                  Advanced Beginner
                </small>                
              </div>
            </article>

            {/* 3 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>Python</h4>
                <small>
                  Experienced
                </small>                
              </div>
            </article>

            {/* 3 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>ASP.NET</h4>
                <small>
                  Advanced Beginner
                </small>                
              </div>
            </article>

            {/* 3 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>Node JS</h4>
                <small>
                  Advanced Beginner
                </small>                
              </div>
            </article>
            {/* 4 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>MySQL (relational)</h4>
                <small>
                  Competent
                </small>                
              </div>
            </article>

            {/* 5 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>PostgreSQL (relational)</h4>
                <small>
                  Advanced Beginner
                </small>                
              </div>
            </article>

            {/* 5 */}
            <article className='experience__details'>
              <GiCheckMark className='experience__details-icon' />
              <div>
                <h4>MongoDB (non-relational, document)</h4>
                <small>
                  Advanced Beginner
                </small>                
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience