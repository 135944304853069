import React from 'react'
import './footer.css'
import {BsTwitter, BsFacebook, BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#footer" className='footer__logo'>Kent Pedrocha</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/kpeds25/"><BsTwitter /></a>
        <a href="https://www.instagram.com/kentpedrocha/"><BsInstagram /></a>
        <a href="https://www.facebook.com/kpeds25/"><BsFacebook /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Kent Pedrocha. All rights reserved 2022.</small>
      </div>
    </footer>
  )
}

export default Footer