import React from 'react'
import './nav.css'
import {RiHomeHeartLine, RiBookmark3Line, RiContactsBook2Line} from 'react-icons/ri'
import {SiAboutdotme, SiBookstack} from 'react-icons/si'
import { useState } from 'react'


const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')

  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav ==='#' ? 'active' : ''}><RiHomeHeartLine/></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav ==='#about' ? 'active' : ''}><SiAboutdotme/></a>
      <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav ==='#portfolio' ? 'active' : ''} ><RiBookmark3Line/></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav ==='#experience' ? 'active' : ''}><SiBookstack/></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav ==='#contact' ? 'active' : ''}><RiContactsBook2Line/></a>
    </nav>
  )
}

export default Nav