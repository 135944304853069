import React from 'react'
import './about.css'
import ME from '../../assets/img/header-kent.png'
import {FaAward} from 'react-icons/fa'
import {RiUserStarFill, RiFolderChartFill} from 'react-icons/ri'
import RubberBand from 'react-reveal/RubberBand';

const About = () => {
  return (
    <section id='about'> 
          <h5>“Life can only be understood backwards; but it must be lived forwards.”
― <i>Søren Kierkegaard</i></h5>
      <RubberBand><h2>About</h2></RubberBand>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>As of now, I have no experince, but I learned how to create from simple to complex websites.</small>
            </article>
            {/*  */}

            <article className="about__card">
              <RiUserStarFill className='about__icon'/>
              <h5>Clients</h5>
              <small>I gained 4 clients from my churchmates including-- friends of friends.</small>
            </article>
            {/*  */}

            <article className="about__card">
              <RiFolderChartFill className='about__icon'/>
              <h5>Academic Competence</h5>
              <small>Dean’s List Honoree Fall 2019 with a 3.62 GPA and Fall 2020 with a 3.60 GPA.</small>
            </article>
          </div>

          <p>
            Did I qualify to your specifications?
          </p>
          <a href="#contact" className='btn btn-primary'>Let's talk</a>
        </div>

      </div>

    </section>
  )
}

export default About